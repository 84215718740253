"use client";

import React from "react";
import { useRouter } from "next/navigation";
import { Button, Grid, TextField, Typography } from "@mui/material";
import Link from "next/link";
import { createClient } from "@/utils/supabase/client";
import clearCachesByServerAction from "@/utils/revalidate";

const Page = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const router = useRouter();
  const supabase = createClient();

  const handleForm = async (event: any) => {
    event.preventDefault();

    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      return console.error(error);
    }

    clearCachesByServerAction(`/`);
    return router.push("/");
  };
  return (
    <Grid container mt={8}>
      <Grid item xs={12} display="flex" justifyContent="center">
        <Typography variant="h4" sx={{ color: "#EFF6E0" }}>
          Log In
        </Typography>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        <form onSubmit={handleForm} style={{ width: "100%" }}>
          <label htmlFor="email">
            <Typography sx={{ color: "#EFF6E0" }}>Email</Typography>
            <TextField
              placeholder="Email"
              variant="outlined"
              id="email"
              type="email"
              name="email"
              sx={{
                width: "100%",
                "& .MuiInputBase-input": {
                  borderRadius: 4,
                  position: "relative",
                  backgroundColor: "#EFF6E0",
                  border: "1px solid",
                  borderColor: "#598392",
                  fontSize: 16,
                  width: "100%",
                  padding: "10px 12px",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#598392",
                    borderRadius: 4,
                  },
              }}
              onChange={(e: any) => setEmail(e.target.value)}
              required
            />
          </label>
          <label htmlFor="password">
            <Typography sx={{ color: "#EFF6E0" }}>Password</Typography>
            <TextField
              placeholder="Password"
              variant="outlined"
              id="password"
              type="password"
              name="password"
              sx={{
                width: "100%",
                "& .MuiInputBase-input": {
                  borderRadius: 4,
                  position: "relative",
                  backgroundColor: "#EFF6E0",
                  border: "1px solid",
                  borderColor: "#598392",
                  fontSize: 16,
                  width: "100%",
                  padding: "10px 12px",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#598392",
                    borderRadius: 4,
                  },
              }}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </label>
          <Grid display="flex" mt={4} justifyContent="center">
            <Button
              variant="outlined"
              type="submit"
              sx={{
                color: "#598392",
                border: "1px solid #598392",
                ":hover": {
                  border: "1px solid #AEC3B0",
                },
                marginRight: 8,
              }}
              data-umami-event="signin-button"
              data-umami-event-email={email}
            >
              Log In
            </Button>
            <Link href="/signup" style={{ textDecoration: "none" }}>
              <Button
                variant="outlined"
                type="submit"
                sx={{
                  color: "#598392",
                  border: "1px solid #598392",
                  ":hover": {
                    border: "1px solid #AEC3B0",
                  },
                }}
              >
                Sign Up
              </Button>
            </Link>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default Page;
